<template>
	<div class="content-wrapper">
		<bo-page-title v-if="isList" />
		<div v-if="isList" class="content pt-0">
			<div class="card">
				<div class="bg-white card-header">
					<div class="row align-items-center">
						<div class="col-md-4 col-lg-5">
							<div class="form-row align-items-center">
								<div class="col-lg-4">
									<h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
								</div>
								<div class="col-lg-8">
									<div class="form-group mb-0">
										<div class="input-group">
											<date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
												:autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
												:ranges="datePickerConfig.ranges" @update="updateValues">
												<template v-slot:input="picker">
													{{ picker.startDate | date }} - {{ picker.endDate | date }}
												</template>
											</date-range-picker>
											<div class="input-group-append calendar-group">
												<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card-header">
					<div class="row">
						<div class="col-md-8 col-lg-9">
							<div class="row gx-1">
								<div class="col-md-auto">
									<div class="form-group mb-0">
										<button @click="doRefreshData" data-toggle="modal" data-target="#FilterField"
											data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i
												class="icon-spinner11"></i></button>
									</div>
								</div>
								<div class="col-md">
									<div class="row g-1">
										<div class="col-md-6 col-lg-3">
											<v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_rekonsil"
												:options="Config.mr.configStatusRekonsil" label="text" :reduce="v=>v.value"></v-select>
										</div>
										<div class="col-md-6 col-lg-4">
											<b-form-group class="mb-0">
												<v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"
													:options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
											</b-form-group>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
								<input class="form-control" v-model="filter.search" @input="doSearch"
									placeholder="Ketik Nama/No. RM Pasien" />
								<div class="form-control-feedback">
									<i class="icon-search4 text-indigo"></i>
								</div>

								<b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
									@click="doResetData()">Reset</b-button>
							</div>
						</div>
					</div>
				</div>



				<div class="tab-content">
					<div :class="'tab-pane fade show active'">
						<div class="table-responsive">
							<table
								class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
								<thead>
									<tr>
										<th>NO.</th>
										<th class="tbl-sticky-col tsc-left">NO. KEDATANGAN</th>
										<th>NO RM</th>
										<th>DPJP</th>
										<th>DIAGNOSA</th>
										<th class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">NAMA/NO KTP</th>
										<th>TANGGAL LAHIR</th>
										<th>USIA</th>
										<th>JENIS KELAMIN</th>
										<th>TANGGAL DAFTAR</th>
										<th>CARA BAYAR</th>
										<th>STATUS</th>
										<th class="tbl-sticky-col tsc-right tsc-right-first">AKSI</th>
									</tr>
								</thead>

								<tbody v-if="(dataList||[]).length">
									<tr v-for="(v,k) in (dataList||[])" :key="k">
										<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
										<td class="tbl-sticky-col tsc-left">
											<div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
										</td>
										<td>
											<div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
											<small
												:class="v.ap_is_old_pasien == 'Y' ? 'font-weight-semibold text-danger' : 'font-weight-semibold text-primary'"
												style="font-size: 12px;">
												{{v.ap_is_old_pasien == 'Y' ? 'PASIEN LAMA' : 'PASIEN BARU'}}</small>
										</td>


										<td>
											{{v.bu_full_name||"-"}}
										</td>

										<td>
											<a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa"
												class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
										</td>

										<td class="tbl-sticky-col tsc-left tsc-left-last" style="--tsc-pos: 115px;">
											<a href="javascript:;" v-if="v.aranr_is_have_rekonsiliasi != 'Y'" @click="periksaPasien(v)"
												class="font-weight-semibold border-bottom" v-b-tooltip.hover.right data-popup="tooltip"
												:title="'Buat Rekonsiliasi Obat'"> {{uppercaseWord(v.ap_fullname)||"-"}}</a>

											<a href="javascript:;" v-else-if="v.aranr_is_have_rekonsiliasi == 'Y' && otherConditional(v)"
												@click="periksaPasien(v)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right
												data-popup="tooltip" :title="'Edit Rekonsiliasi Obat'">
												{{uppercaseWord(v.ap_fullname)||"-"}}</a>

											<p v-else class="font-weight-semibold" v-b-tooltip.hover.right data-popup="tooltip">
												{{uppercaseWord(v.ap_fullname)||"-"}}</p>

											<br />

											<small class="text-danger" style="font-size: 10px;"
												v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

											<div class="text-info">{{v.ap_nik||"-"}}</div>

										</td>

										<td>
											<div v-if="v.ap_dob" class="font-weight-semibold">
												{{v.ap_dob | moment("DD MMM YYYY")}}
											</div>
											<span v-else> - </span>
										</td>
										<td>
											{{v.ap_usia_with_ket||"-"}}
											<span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
										</td>

										<td>
											{{v.ap_gender == 1 ? "Laki-Laki" : v.ap_gender == 2 ? "Perempuan" : "Tidak Diketahui"}}
										</td>

										<td>
											<div class="font-weight-semibold">{{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm") }}</div>
										</td>

										<td>
											<div class="font-weight-semibold">
												{{getConfigDynamic(Config.mr.statusJaminan,v.aranres_jaminan)||"-"}}</div>
										</td>

										<td>
											<span class="badge badge-success mb-1" v-if="v.aranr_is_have_rekonsiliasi == 'Y'">Selesai</span>
											<span class="badge badge-warning mb-1" v-else>Menunggu</span>
										</td>
										<td class="tbl-sticky-col tsc-right tsc-right-first">
											<a href="javascript:;" v-if="v.aranr_is_have_rekonsiliasi != 'Y'" @click="periksaPasien(v)"
												v-b-tooltip.hover.right data-popup="tooltip" :title="'Buat Rekonsiliasi Obat'"
												class="mb-1 btn btn-icon rounded-round btn-sm alpha-info border-info"><i
													class="icon-file-plus"></i></a>

											<a href="javascript:;" v-if="v.aranr_is_have_rekonsiliasi == 'Y' && otherConditional(v)"
												@click="periksaPasien(v)" v-b-tooltip.hover.right data-popup="tooltip"
												:title="'Edit Rekonsiliasi Obat'"
												class="mb-1 btn btn-icon rounded-round btn-sm alpha-info border-info"><i
													class="icon-file-plus"></i></a>

											<a href="javascript:;" @click="periksaPasien(v,true)" v-b-tooltip.hover.right
												class="btn btn-icon rounded-round btn-sm alpha-info border-info"
												title="Lihat Rekonsiliasi Obat"><i class="icon-file-eye"></i></a>
										</td>
									</tr>
								</tbody>
								<tbody v-if="data.data.length == 0">
									<tr>
										<td colspan="99">
											<div class="text-center">
												<h4 align="center"><span
														v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
														tidak ditemukan</span>
													<span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
											</div>
										</td>
									</tr>
								</tbody>
								<tbody v-if="!data.data">
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
									<tr>
										<td colspan="99">
											<div class="skeletal-comp"></div>
										</td>
									</tr>
								</tbody>
							</table>

							<div
								:class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
								<a href="javascript:;" data-scroll="left"
									class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
									<i class="icon-chevron-left"></i>
								</a>
								<a href="javascript:;" data-scroll="right"
									class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
									<i class="icon-chevron-right"></i>
								</a>
							</div>
						</div>

						<b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
							<b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
						</b-card-footer>
					</div>
				</div>

			</div>
		</div>
		<template v-else>
			<Form :row.sync="row" v-bind="passToSub" />
		</template>
	</div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')
    
    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'
    import Form from './Form.vue'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,Form
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowReg: this.rowReg,
                }
            },
        },
        data(){
            return {
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,

                
                belumTriase: 0,
                Esi1: 0,
                Esi3: 0,
                Esi5: 0,
                EsiDoa: 0,

                rowEdit : {},
                rowReg : {},

                tabDraft : 'N',
            }
        },
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'
            this.filter.status_rujukan = 'ALL'

            this.filter.is_draft = this.tabDraft
            
            this.doConvertDate()      
            this.apiGet()
            
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            otherConditional(v){
                return moment().format('YYYY-MM-DD') <= this.to24Hours(v.aranr_reg_date)
            },
            to24Hours(date){
                return moment(date).add(12, 'hours').format('YYYY-MM-DD')
            },
            isSameName(name){
                let index = this.dataList.findIndex(x=>x.ap_fullname == name)
                let count = 0
                for (let i = 0; i < this.dataList.length; i++) {
                    if (this.dataList[i].ap_fullname === name) {
                        count++
                    }
                }
                if(count > 1){
                    return "text-warning"
                }else{
                    return ""
                }
            },
            periksaPasien(v,isDetail=false){
                if(isDetail){
                    this.$router.push({ name: 'RanapRekonsiliasi', params: { pageSlug: v.aranr_id }, query:{isDetail: true} }).catch(()=>{})
                }else{
                    this.$router.push({ name: 'RanapRekonsiliasi', params: { pageSlug: v.aranr_id } }).catch(()=>{})
                }
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            changeTab(tab){
                this.tabDraft = tab
                this.filter.is_draft = this.tabDraft
                this.doFill()
            },

            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            
            lihatDiagnosa(v,k){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:{type:'lihat-diagnosa', id: v.aranr_id}}, 
                    "POST"
                ).then(res=>{
                    this.dataList[k]['diagnosa'] = res.data        
                })
            },
            
            uppercaseWord(e=""){
              if(e){
                const words = String(e)
                return words.toUpperCase()
              }else{
                return null
              }
            },
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>