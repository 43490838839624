<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />
    
    <div class="content">
      <div class="page-title pt-0 pb-3">
        <a href="javascript:;" @click="back" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </a>
      </div>

      
      <div class="card">
        <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold" v-if="!isDetail">Buat Rekonsiliasi Obat</h6>
            <h6 class="card-title font-weight-semibold" v-else>Rekonsiliasi Obat</h6>
        </div>
        <validation-observer ref="VFormSubmit">
        <b-form @submit.prevent="doSubmit">
        <div class="card-body">
            <template v-if="!isDetail">
                <div class="form-group">
                <label for="">Daftar Obat-Obatan Pasien</label>
                
                    <validation-observer ref="VFormObat">
                    <div class="table-responsive">
                    <b-table
                        bordered
                        striped
                        small
                        hover
                        :fields="farmTableFields"
                        table-class="w-auto"
                        :items="data"
                    >
                        <template #cell(aksi)="rowTable">
                            <a @click="removeObat(rowTable.item,rowTable.index)" href="javascript:;"
                                    class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"
                                    data-popup="tooltip" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                        </template>

                        <template #cell(aranrfr_date)="rowTable">
                            <div class="input-group">
                                <datepicker v-model="data[rowTable.index]['aranrfr_date']" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                            <VValidate :name="'Tanggal '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_nama" :rules="{required : 1}" />
                        </template>

                        
                        <template #cell(aranrfr_nama)="rowTable">
                            <b-form-input v-model="data[rowTable.index]['aranrfr_nama']"/>
                            <VValidate :name="'Nama Obat '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_nama" :rules="{required : 1}" />
                        </template>
                        
                        <template #cell(aranrfr_rute)="rowTable">
                            <b-form-input v-model="data[rowTable.index]['aranrfr_rute']"/>
                            <VValidate :name="'Rute Pemberian '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_rute" :rules="{required : 1}" />
                        </template>
                                                
                        <template #cell(aranrfr_perubahan_aturan_pakai)="rowTable">
                            <b-form-input v-model="data[rowTable.index]['aranrfr_perubahan_aturan_pakai']"/>
                        </template>



                        <template #cell(aranrfr_waktu_pemberian)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_waktu_pemberian"
                                :options="Config.mr.waktuPemberian"
                                label="text"
                                :reduce="v=>v.value"
                            />
                            <VValidate :name="'Waktu Pemberian Saat Admisi '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_waktu_pemberian" :rules="{required : 1}" />
                        </template>
                        
                        <template #cell(aranrfr_waktu_pemberian_perawatan)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_waktu_pemberian_perawatan"
                                :options="Config.mr.waktuPemberian"
                                label="text"
                                :reduce="v=>v.value"
                            />
                            <VValidate :name="'Waktu Pemberian Saat Perawatan '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_waktu_pemberian_perawatan" :rules="{required : 1}" />
                        </template>
                        
                        <template #cell(aranrfr_waktu_pemberian_pulang)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_waktu_pemberian_pulang"
                                :options="Config.mr.waktuPemberian"
                                label="text"
                                :reduce="v=>v.value"
                            />
                            <VValidate :name="'Waktu Pemberian Saat Pulang '+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_waktu_pemberian_pulang" :rules="{required : 1}" />
                        </template>
                        
                        <template #cell(aranrfr_tindak_lanjut_pemberian)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian"
                                :options="Config.mr.tindakLanjutFarmasi"
                                label="text"
                                :reduce="v=>v.value"
                            >
                            </v-select>
                            <VValidate :name="'Tindak Lanjut Pemberian Saat Admisi'+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian" :rules="{required : 1}" />
                        </template>

                        <template #cell(aranrfr_tindak_lanjut_pemberian_perawatan)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian_perawatan"
                                :options="Config.mr.tindakLanjutFarmasi"
                                label="text"
                                :reduce="v=>v.value"
                            >
                            </v-select>
                            <VValidate :name="'Tindak Lanjut Pemberian Saat Perawatan'+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian_perawatan" :rules="{required : 1}" />
                        </template>
                        
                        <template #cell(aranrfr_tindak_lanjut_pemberian_pulang)="rowTable">
                            <v-select
                                placeholder="Pilih Item"
                                v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian_pulang"
                                :options="Config.mr.tindakLanjutFarmasi"
                                label="text"
                                :reduce="v=>v.value"
                            >
                            </v-select>
                            <VValidate :name="'Tindak Lanjut Pemberian Saat Pulang'+(rowTable.index+1)" v-model="data[rowTable.index].aranrfr_tindak_lanjut_pemberian_pulang" :rules="{required : 1}" />
                        </template>

                        <template #cell(aranrfr_jumlah)="rowTable">
                            <div class="input-group">
                                <b-form-input type="text" v-model="data[rowTable.index]['aranrfr_jumlah']" :formatter="$parent.number" class="form-control" id="itemCount" />
                            </div>
                            <VValidate :name="'Jumlah '+(rowTable.index+1)" v-model="data[rowTable.index]['aranrfr_jumlah']" :rules="{required : 1}" />
                        </template>
                        <template #cell(aranrfr_frekuensi)="rowTable">
                            <span class="d-flex">
                                <v-select style="flex:1;" placeholder="Pilih Frekuensi" v-model="data[rowTable.index].aranrfr_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <a href="javascript:;" @click="data[rowTable.index]['aranrfr_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                            </span>
                            <VValidate :name="'Frekuensi '+(rowTable.index+1)" v-model="data[rowTable.index]['aranrfr_frekuensi']" :rules="{required : 1}" />
                            <div v-if="data[rowTable.index]['aranrfr_frekuensi'] == 99999">
                                <b-form-input type="text" v-model="data[rowTable.index]['aranrfr_frekuensi_lainnya']" class="form-control" id="itemCount" />
                                <VValidate :name="'Frekuensi Lainnya '+(rowTable.index+1)" v-model="data[rowTable.index]['aranrfr_frekuensi_lainnya']" :rules="{required : 1}" />
                            </div>
                        </template>
                        <template #custom-foot>
                            <tr>
                                <td class="text-center" colspan="99">
                                    <b-button @click="openObat()" variant="outline-primary" class="btn alpha-blue">
                                        <i class="icon-plus2 mr-1 align-middle"></i>
                                        <span class="align-middle">Tambah Resep</span>
                                    </b-button>
                                </td>
                            </tr>
                        </template>
                    </b-table>
                    </div>
                    </validation-observer>



                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="head_panel_red">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3>ALERGI </h3>
                                <div>
                                <b-form-radio-group
                                    id="has_alergi"
                                    @input="changeAlergi($event)"
                                    :options="Config.mr.yesNoOpt"
                                    v-model="row.aranro_has_alergi"
                                />
                                <VValidate 
                                    name="Alergi" 
                                    v-model="row.aranro_has_alergi" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>
                            </div>
                            
                            <template v-if="row.aranro_has_alergi == 'Y'">
                                <table class="table table-sm table-bordered">
                                    <thead>
                                    <tr>
                                        <th width="33%">Jenis</th>
                                        <th>Informasi Alergi</th>
                                        <th width="64"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v,k) in (row.aranro_alergi||[])" :key="k+'aler'">
                                        <td>
                                            <v-select placeholder="Pilih Jenis" v-model="row.aranro_alergi[k]['jenis']" :options="Config.mr.jenisAlergi"  label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            
                                            <VValidate 
                                                :name="'Obat '+(k+1)" 
                                                v-model="row.aranro_alergi[k]['jenis']" 
                                                :rules="{required:1}"
                                            />
                                        </td>
                                        <td>
                                            <vue-bootstrap-autocomplete
                                            v-model="row.aranro_alergi[k]['name']"
                                            :data="mAlergi" @input="searchAlergi(row.aranro_alergi[k]['name'])"
                                            placeholder="Pilih Alergi"
                                            />
                                            
                                            <VValidate 
                                                :name="'Obat '+(k+1)" 
                                                v-model="row.aranro_alergi[k]['name']" 
                                                :rules="{required:1}"
                                            />
                                        </td>
                                        <td>
                                        <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colspan="3" class="text-center">
                                        <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>         
                            </template>
                            <template v-else>
                                <table class="table table-sm table-bordered">
                                    <thead>
                                    <tr>
                                        <th width="33%">Jenis</th>
                                        <th>Informasi Alergi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </td>
                                        <td>
                                        <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                <table class="table table-bordered table-sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Penilaian</th>
                        <th>Parameter</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td>Belum mencapai tanggal kedaluwarsa / <em>Beyond use date</em> (obat racikan)</td>
                        <td>
                        <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="row['aranro_p1']" />
                            <VValidate :name="'Penilaian 1'" v-model="row['aranro_p1']" :rules="{required : 1}" />
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Penyimpanan obat baik (masih terbungkus dalam kemasan obat)</td>
                        <td>
                        <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="row['aranro_p2']" />
                            <VValidate :name="'Penilaian 2'" v-model="row['aranro_p2']" :rules="{required : 1}" />
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Tidak ada perubahan bentuk fisik sediaan obat</td>
                        <td>
                        <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="row['aranro_p3']" />
                            <VValidate :name="'Penilaian 3'" v-model="row['aranro_p3']" :rules="{required : 1}" />
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Label etiket obat terlihat jelas di masing-masing obat</td>
                        <td>
                        <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="row['aranro_p4']" />
                            <VValidate :name="'Penilaian 4'" v-model="row['aranro_p4']" :rules="{required : 1}" />
                        </div>
                        </td>
                    </tr>
                    <tr class="table-info">
                        <td colspan="2"><strong>Kesimpulan penilaian kelayakan obat</strong></td>
                        <td>
                        <div>
                            <b-form-radio-group :options="Config.mr.layakTidakLayakOpt"
                            v-model="row['aranro_kesimpulan']" />
                            <VValidate :name="'Kesimpulan'" v-model="row['aranro_kesimpulan']" :rules="{required : 1}" />
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tanda tangan pada saat pasien masuk</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <div class="form-group mb-2">
                          <label for="">Nama Pasien / Keluarga</label> 
                          <b-form-input type="text" class="form-control" v-model="row.aranro_pasien_masuk_nama"/>
                          <VValidate :name="'Nama Pasien / Keluarga'" v-model="row.aranro_pasien_masuk_nama" :rules="{required: 1, min: 3, max: 128}" />                   
                        </div>
                        <div class="form-group">
                          <label for="">Tanda Tangan Pasien / Keluarga</label>
                          <div class="signing-element" data-target-id="ttdPasien">
                              <VueSignaturePad
                                  class="sign-container"
                                  width="100%"
                                  height="160px"
                                  ref="ttdPasien"
                              />
                              <div class="mt-2">
                              <button type="button" @click="undoTTD('ttdPasien','aranro_pasien_masuk_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                  <b><i class="icon-cross3"></i></b>
                                  <span>Clear</span>
                              </button>
                              
                              <button type="button" @click="output('ttdPasien','aranro_pasien_masuk_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                  <b><i class="icon-checkmark2"></i></b>
                              <span>Simpan</span>
                              </button>
                              </div>
                          </div>
                          <VValidate :name="'Tanda Tangan Pasien / Keluarga'" v-model="row.aranro_pasien_masuk_ttd" :rules="{required: 1}" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-2">
                          <label for="">Nama Petugas</label> 
                          <b-form-input type="text" class="form-control" v-model="row.aranro_petugas_masuk_nama"/>
                          <VValidate :name="'Nama Petugas'" v-model="row.aranro_petugas_masuk_nama" :rules="{required: 1, min: 3, max: 128}" />                   
                        </div>
                        <div class="form-group">
                          <label for="">Tanda Tangan Petugas</label>
                          <div class="signing-element" data-target-id="ttdPetugas">
                              <VueSignaturePad
                                  class="sign-container"
                                  width="100%"
                                  height="160px"
                                  ref="ttdPetugas"
                              />
                              <div class="mt-2">
                              <button type="button" @click="undoTTD('ttdPetugas','aranro_petugas_masuk_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                  <b><i class="icon-cross3"></i></b>
                                  <span>Clear</span>
                              </button>
                              
                              <button type="button" @click="output('ttdPetugas','aranro_petugas_masuk_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                  <b><i class="icon-checkmark2"></i></b>
                              <span>Simpan</span>
                              </button>
                              </div>
                          </div>
                          <VValidate :name="'Tanda Tangan Petugas'" v-model="row.aranro_petugas_masuk_ttd" :rules="{required: 1}" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </template>
            <template v-else>
                <div class="form-group">
                    <label for="">Daftar Obat-Obatan Petugas</label>
                    <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                        <th>Tanggal</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Frekuensi</th>
                        <th>Rute</th>
                        <th>Waktu Pemberian Terakhir Saat Admisi</th>
                        <th>Waktu Pemberian Terakhir Saat Perawatan</th>
                        <th>Waktu Pemberian Terakhir Saat Pulang</th>

                        <th>Tindak Lanjut Rekonsiliasi Saat Admisi</th>
                        <th>Tindak Lanjut Rekonsiliasi Saat Perawatan</th>
                        <th>Tindak Lanjut Rekonsiliasi Saat Pulang</th>
                        <th>Perubahan Aturan Pakai</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (data||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_date | moment("DD MMMM YYYY")}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            {{v.aranrfr_nama||"-"}}
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_jumlah||"-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                            <p>
                                <span v-if="v.aranrfr_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                <span v-else>{{v.aranrfr_frekuensi_lainnya || "-"}}</span>
                            </p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_rute || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_waktu_pemberian || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_waktu_pemberian_perawatan || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_waktu_pemberian_pulang || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_tindak_lanjut_pemberian || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_tindak_lanjut_pemberian_perawatan || "-"}}</p>
                            </div>
                        </td>                      
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_tindak_lanjut_pemberian_pulang || "-"}}</p>
                            </div>
                        </td>
                        
                        <td>
                            <div class="result_tab">
                            <p>{{v.aranrfr_perubahan_aturan_pakai || "-"}}</p>
                            </div>
                        </td>
                        
                        </tr>
                        <tr v-if="!(data||[]).length" class="table-info">
                            <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Penilaian</th>
                            <th>Parameter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>Belum mencapai tanggal kedaluwarsa / <em>Beyond use date</em> (obat racikan)</td>
                            <td>
                            <div>
                                {{row.aranro_p1 == 'Y'?'Ya':'Tidak'}}
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Penyimpanan obat baik (masih terbungkus dalam kemasan obat)</td>
                            <td>
                            <div>
                                {{row.aranro_p2 == 'Y'?'Ya':'Tidak'}}
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Tidak ada perubahan bentuk fisik sediaan obat</td>
                            <td>
                            <div>
                                {{row.aranro_p3 == 'Y'?'Ya':'Tidak'}}                                
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Label etiket obat terlihat jelas di masing-masing obat</td>
                            <td>
                            <div>
                                {{row.aranro_p4 == 'Y'?'Ya':'Tidak'}}                                
                            </div>
                            </td>
                        </tr>
                        <tr class="table-info">
                            <td colspan="2"><strong>Kesimpulan penilaian kelayakan obat</strong></td>
                            <td>
                            <div>
                                {{row.aranro_kesimpulan == 'L'?'Layak':'Tidak Layak'}}                                
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tanda tangan pada saat pasien pulang</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row mt-2">
                      <div class="col-md-4">
                        <div class="form-group mb-2">
                          <label for="">Nama Pasien / Keluarga</label> 
                          <b-form-input type="text" class="form-control" v-model="row.aranro_pasien_pulang_nama"/>
                          <VValidate :name="'Nama Pasien / Keluarga'" v-model="row.aranro_pasien_pulang_nama" :rules="{required: 1, min: 3, max: 128}" />                   
                        </div>
                        <div class="form-group">
                          <label for="">Tanda Tangan Pasien / Keluarga</label>
                          <div class="signing-element" data-target-id="ttdPasienKeluar">
                              <VueSignaturePad
                                  class="sign-container"
                                  width="100%"
                                  height="160px"
                                  ref="ttdPasienKeluar"
                              />
                              <div class="mt-2">
                              <button type="button" @click="undoTTD('ttdPasienKeluar','aranro_pasien_pulang_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                  <b><i class="icon-cross3"></i></b>
                                  <span>Clear</span>
                              </button>
                              
                              <button type="button" @click="output('ttdPasienKeluar','aranro_pasien_pulang_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                  <b><i class="icon-checkmark2"></i></b>
                              <span>Simpan</span>
                              </button>
                              </div>
                          </div>
                          <VValidate :name="'Tanda Tangan Pasien / Keluarga'" v-model="row.aranro_pasien_pulang_ttd" :rules="{required: 1}" />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-2">
                          <label for="">Nama Petugas</label> 
                          <b-form-input type="text" class="form-control" v-model="row.aranro_petugas_pulang_nama"/>
                          <VValidate :name="'Nama Petugas'" v-model="row.aranro_petugas_pulang_nama" :rules="{required: 1, min: 3, max: 128}" />                   
                        </div>
                        <div class="form-group">
                          <label for="">Tanda Tangan Petugas</label>
                          <div class="signing-element" data-target-id="ttdPetugasKeluar">
                              <VueSignaturePad
                                  class="sign-container"
                                  width="100%"
                                  height="160px"
                                  ref="ttdPetugasKeluar"
                              />
                              <div class="mt-2">
                              <button type="button" @click="undoTTD('ttdPetugasKeluar','aranro_petugas_pulang_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                                  <b><i class="icon-cross3"></i></b>
                                  <span>Clear</span>
                              </button>
                              
                              <button type="button" @click="output('ttdPetugasKeluar','aranro_petugas_pulang_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                                  <b><i class="icon-checkmark2"></i></b>
                              <span>Simpan</span>
                              </button>
                              </div>
                          </div>
                          <VValidate :name="'Tanda Tangan Petugas'" v-model="row.aranro_petugas_pulang_ttd" :rules="{required: 1}" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </template>

        </div>
        <div class="card-footer">
            <div class="text-right">
            <button @click="back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
            <button @click="saveSementara()" type="button" class="btn btn-info mr-2">Simpan Sementara</button>
            <button v-if="!isDetail" type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
            <button v-if="isDetail" @click="downloadLaporan" type="button" class="btn btn-primary">Unduh Laporan <i class="icon-paperplane ml-2"></i></button>
            </div>
        </div>
        </b-form>
        </validation-observer>
      </div>
    </div>

  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  const moment = require('moment')
  import Datepicker from 'vuejs-datepicker'

  export default {
    extends: GlobalVue,
    data() {
      return {
        patientData: {},
        loading: {
          patientInfo: false,
        },
        rowReg: {},
        farmTableFields: [
            { key: 'aksi', thAttr: { width: 48 } },
            { key: 'aranrfr_date', label: 'Tanggal', thAttr: { width: 260 }, thStyle: {minWidth: '260px' } },
            { key: 'aranrfr_nama', label: 'Nama', thAttr: { width: 320 }, thStyle: {minWidth: '320px' } },
            { key: 'aranrfr_jumlah', label: 'Jumlah', thAttr: { width: 150 }, thStyle: {minWidth: '150px' } },
            { key: 'aranrfr_frekuensi', label: 'Frekuensi', thAttr: { width: 210 }, thStyle: {minWidth: '210px' } },
            { key: 'aranrfr_rute', label: 'Rute', thAttr: { width: 200 }, thStyle: {minWidth: '160px' }},
            { key: 'aranrfr_waktu_pemberian', label: 'Waktu Pemberian Saat Admisi', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
            { key: 'aranrfr_waktu_pemberian_perawatan', label: 'Waktu Pemberian Saat Perawatan', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
            { key: 'aranrfr_waktu_pemberian_pulang', label: 'Waktu Pemberian Saat Pulang', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
            { key: 'aranrfr_tindak_lanjut_pemberian', label: 'Tindak Lanjut Pemberian Saat Admisi', thAttr: { width: 260 }, thStyle: {minWidth: '260px' } },
            { key: 'aranrfr_tindak_lanjut_pemberian_perawatan', label: 'Tindak Lanjut Pemberian Saat Perawatan', thAttr: { width: 260 }, thStyle: {minWidth: '260px' } },
            { key: 'aranrfr_tindak_lanjut_pemberian_pulang', label: 'Tindak Lanjut Pemberian Saat Pemberian Pulang', thAttr: { width: 260 }, thStyle: {minWidth: '260px' } },
            { key: 'aranrfr_perubahan_aturan_pakai', label: 'Perubahan Aturan Pakai', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
        ],
        mDosis: [],
        mTypeSatuan: [],
        data: [],
        mAlergi: [],
      }
    },
    computed : {
        isDetail(){
          return this.$route.query.isDetail
        }
    },
    components: {
      PatientInfo,
      Datepicker
    },
    
    methods: {
      undoTTD(refs,field){
        this.$refs[refs].clearSignature()
        this.row[field] = null
      },
      output(refs,field) {
        let { isEmpty, data } = this.$refs[refs].saveSignature()
        if(!isEmpty){
          this.row[field] = data
        }
      },
      saveSementara(){
        this.loadingOverlay = true
        let data = this.row
        data.aranr_id = this.rowReg.aranr_id
        data.type = 'simpan-sementara'

        Gen.apiRest(
            "/do/"+'RanapRekonsiliasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
                title: resp.message,
                icon: resp.status,
                confirmButtonText: 'Ok'
            }).then(result => {
                if (result.value) {
                  this.$router.push({ name: 'RanapRekonsiliasi' }).catch(()=>{})  
                }
            })
        }).catch(err=>{
            this.loadingOverlay = false
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
        })
      },
      changeAlergi(e){
        if(!(this.row.aranro_alergi||[]).length){
          this.addAlergi()
        }
      },
      
      searchAlergi: _.debounce(function (e) {
        let data = {
            name: e,
            type: 'get-alergi'
        }
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.mAlergi = res.data.data            
        })
      }, 100),
      
      removeAlergi(k){
        if(!Array.isArray(this.row.aranro_alergi)){
            this.$set(this.row, 'aranro_alergi', [])
        }
        this.row.aranro_alergi.splice(k,1)
      },
      
      addAlergi(){
        const data = {
            name : '',
            jenis : null,
        }
        if(!Array.isArray(this.row.aranro_alergi)){
            this.$set(this.row, 'aranro_alergi', [])
        }
        this.row.aranro_alergi.push(data)
      },

      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },
      
      back(){
        this.$router.back()
      },

        
      openObat(v=null){
          let data = {
              aranrfr_aranr_id: this.rowReg.aranr_id,
              aranrfr_date: moment().format("YYYY-MM-DD"),
              aranrfr_nama : null,
              aranrfr_jumlah : null,
              aranrfr_satuan : null,
              aranrfr_frekuensi : null,
              aranrfr_frekuensi_lainnya : null,
              aranrfr_keterangan : null,
              aranrfr_rute : null,
              aranrfr_waktu_pemberian : null,
              aranrfr_tindak_lanjut_pemberian : null,
              aranrfr_perubahan_aturan_pakai : null,

              id : null
          }
          data.type = 'add-resep-dokter'
          Gen.apiRest(
              "/do/"+'RanapRekonsiliasi',
              {data:data}, 
              "POST"
          ).then(res=>{
              this.apiGet()
              this.openAddObat = true
          })
      },
      
      removeObat(v,k){
        let data = {
            type : 'remove-resep-dokter',
            id: v.aranrfr_id
        }
        Gen.apiRest(
            "/do/"+'RanapRekonsiliasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.data.splice(k,1)
            this.openAddObat = false
        })
      },

      downloadLaporan(){
        this.loadingOverlay = true
        let data = this.row
        data.aranr_id = this.rowReg.aranr_id
        data.type = 'simpan-sementara'

        Gen.apiRest(
            "/do/"+'RanapRekonsiliasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            let data = {
                exptype: 'pdf', type: "export",  regId: this.$route.params.pageSlug        
            }
            let self = this
            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'dataRekonsiliasiRanap'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `rekon-obat_${self.rowReg.aranr_reg_code}_${moment().format("YYYY-MM-DD")}.pdf`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        })
      },

      apiGetMaster(){
          let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
          Gen.apiRest(
              "/get/"+this.modulePage+
              '/master'+`?master=1&regId=${this.pageSlug}`, 
              {}
          ).then(res=>{
              _.forEach(res.data, (v,k)=>{
                  this.$set(this, k, v)
              })
          })
      },
  
      autoSaveResepObat: _.debounce(function (data) {
        let dataPost = {
            type: 'auto-save-resep-obat',
            data: data
        }
        Gen.apiRest(
            "/do/" + 'RanapRekonsiliasi', {
            data: dataPost
            },
            "POST"
        )
      }, 1000),
   
      doSubmit(){
        this.$refs['VFormSubmit'].validate().then(success=>{
          if(success){
              this.$swal({
                  icon: 'warning',
                  title: 'Apakah Anda Yakin akan menyimpan data ini?',
                  showCancelButton: true,
                  confirmButtonText: 'Ya',
                  cancelButtonText: 'Tidak, kembali'
              }).then(result => {
                  if (result.value) {
                      this.loadingOverlay = true
                      let data = this.row
                      data.aranr_id = this.rowReg.aranr_id
                      data.type = 'submit-data'

                      Gen.apiRest(
                          "/do/"+'RanapRekonsiliasi',
                          {data:data}, 
                          "POST"
                      ).then(res=>{
                          this.loadingOverlay = false
                          let resp = res.data
                          resp.statusType = 200
                          this.$swal({
                              title: resp.message,
                              icon: resp.status,
                              confirmButtonText: 'Ok'
                          }).then(result => {
                              if (result.value) {
                                this.$router.push({ name: 'RanapRekonsiliasi' }).catch(()=>{})  
                              }
                          })
        }).catch(err=>{
            this.loadingOverlay = false
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
        })
                  }
              })
          }
        })
      }


    },
    
    mounted() {
      this.apiGet()
      this.getPatientInfo()
      this.apiGetMaster()
    },

    watch:{   
      data: {
        handler(v) {
          this.autoSaveResepObat(v)
        },
        deep: true
      },
      'row.aranro_pasien_masuk_ttd'(v){
        if(this.row.aranro_pasien_masuk_ttd){
          if(this.$refs['ttdPasien']){
            this.$refs['ttdPasien'].fromDataURL(this.row.aranro_pasien_masuk_ttd)
          }
        } 
      },
      'row.aranro_petugas_masuk_ttd'(v){
        if(this.row.aranro_petugas_masuk_ttd){
          if(this.$refs['ttdPetugas']){
            this.$refs['ttdPetugas'].fromDataURL(this.row.aranro_petugas_masuk_ttd)
          }
        } 
      },

      'row.aranro_pasien_pulang_ttd'(v){
        if(this.row.aranro_pasien_pulang_ttd){
          if(this.$refs['ttdPasienKeluar']){
            this.$refs['ttdPasienKeluar'].fromDataURL(this.row.aranro_pasien_pulang_ttd)
          }
        } 
      },
      'row.aranro_petugas_pulang_ttd'(v){
        if(this.row.aranro_petugas_pulang_ttd){
          if(this.$refs['ttdPetugasKeluar']){
            this.$refs['ttdPetugasKeluar'].fromDataURL(this.row.aranro_petugas_pulang_ttd)
          }
        } 
      },

      'row.aranro_petugas_masuk_nama'(v){
        if(!v){
          this.row.aranro_petugas_masuk_nama = this.user.fullName
        }
      },
      'row.aranro_petugas_pulang_nama'(v){
        if(!v){
          this.row.aranro_petugas_pulang_nama = this.user.fullName
        }
      }



      
      

    }

  }

</script>